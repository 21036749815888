import { ApplicationController } from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'form'
  ]

  applyDiscount(e) {
    var formData
    e.preventDefault()
    Ladda.create(e.target).start()
    if (this.formTarget.id.search(/customer/) > -1) {
      formData = this.customerFormData()
    } else if (this.formTarget.id.search(/pass/) > -1) {
      formData = this.passFormData()
    }

    let originalRedirectURL = this.data.get('redirect-url') || window.location.href;
    let currentURL = new URL(window.location.href)
    var redirectURL = new URL(originalRedirectURL)

    var newSearchParams = new URLSearchParams(formData)

    if(originalRedirectURL.searchParams?.has("embed", "true")){
      newSearchParams.set("embed", "true")
    }

    if(currentURL.searchParams?.has("navigable_dialog_id")){
      newSearchParams.set(`navigable_dialog_id`, currentURL.searchParams?.get("navigable_dialog_id"))
    }

    redirectURL.search = newSearchParams.toString()
    window.location.href = redirectURL.toString()
  }

  customerFormData() {
    let formData = new FormData(this.formTarget)

    for(const [key, value] of formData.entries()){
      if(key.startsWith("customer[")){ continue; }
      formData.delete(key)
    }

    return formData
  }

  passFormData() {
    let formData = new FormData(this.formTarget)

    for(const [key, value] of formData.entries()){
      if(key.startsWith("pass[")){ continue; }
      formData.delete(key)
    }

    return formData
  }
}
